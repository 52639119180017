<template>
    <v-card
        class="mb-12"
        height="500px"
    >    
        <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pa-4 pt-6"
        >
            <v-container>
            <v-row>
                <v-col :cols="12">
                    <v-combobox
                        v-model="selectedType"
                        item-text="type"
                        item-value="type"
                        :items="VinicultureTypes"
                        :rules="[typeRule]"
                        label="Sorte"
                        style="min-height: 96px"
                        @change="typeChanged">
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="12" lg="8" tile style="min-width:200px">
                    <v-menu
                        v-model="dataTimePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template class="pr-n12" v-slot:activator="{ on, attrs }">
                            <v-text-field                                                    
                                prepend-icon="mdi-calendar"
                                ref="date_of_construction"
                                v-model="item.date_of_cultivation"
                                :rules="[rules.dateFormat]"
                                v-bind="attrs"
                                v-on="on"
                                label="Pflanzdatum"
                                @change="dataTimePicker = false"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="datePickerValue"
                            :first-day-of-week="0"
                            locale="de-DE"
                            @input="dataTimePicker = false"
                        ></v-date-picker>
                    </v-menu>                   
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="12">
                    <v-subheader class="pl-0 text-h6">Gassenbreite in m</v-subheader>
                    <v-slider
                    v-model="item.row_distance"
                    :step="0.1"
                    :min="0.5"
                    :max="4.0"
                    class="align-center mt-n6"
                    >
                        <template v-slot:append>
                            <v-text-field
                            v-model="item.row_distance"
                            class="mt-0 pt-0"
                            :max="4.0"
                            :min="0.5"
                            :step="0.1"
                            type="number"
                            style="width: 100px"
                            suffix="m"
                            >
                            </v-text-field>
                        </template>
                    </v-slider>
                </v-col>
            </v-row>
            <v-row>
                <v-col :cols="12">
                    <v-subheader class="pl-0 text-h6">Stockabstand in m</v-subheader>
                    <v-slider
                        v-model="item.grapevine_distance"
                        :step="0.1"
                        :min="0.5"
                        :max="4.0"
                        class="align-center mt-n6"
                    >
                        <template v-slot:append>
                            <v-text-field
                                v-model="item.grapevine_distance"
                                class="mt-0 pt-0"
                                :max="4.0"
                                :min="0.5"
                                :step="0.1"
                                type="number"
                                style="width: 100px"
                                suffix="m">
                            </v-text-field>
                        </template>
                    </v-slider>
                </v-col>
            </v-row>
            </v-container>
        </v-form>        
    
    </v-card>
</template>

<script>

export default {
    props:{
        item: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    type:null,
                    date_of_cultivation: "",
                    row_distance: null,
                    grapevine_distance: 22
                }
            }
        },
        state: {
            required: false,
            type: Object,
            default: () => {
                return  {
                    state:{
                            valid:false
                        }
                    // isEdit: false
                }
            }
        },

        isEdit:{                
            required: true,
            default: false,
            type: Boolean
        },

        isVisible:{                
            required: true,
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            valid: false, 
            rules: {
                dateFormat: v => /^\d{4}-\d{2}-\d{2}$|^$/.test(v) || 'Datumsformat YYYY-mm-dd',
            }, 
            dataTimePicker: false,
            datePickerValue : '2022-01-01',
            selectedType: {type:null}
                   
        }

    },

    computed:{

        VinicultureTypes(){
            return this.$store.state.app.availableVinicultureTypes

        },  

    },

    methods:{

        typeChanged(data){
            console.log('typeChanged', data)
            if(data.type)
                this.item.type = data.type
            else
                this.item.type = data

            
            // setTimeout(() => {
            //     this.$refs.form.validate()
            // }, 100); 
            this.state.valid = this.valid && this.item.type && this.item.type != '';  
        },

        typeRule(data){
            console.log('typeRule', data)
            let tmp = data
            if(typeof data != 'string')
                tmp = data.type
            

            const res = !!tmp  || 'This item is required'
            if(res !== true )
                this.valid = false;            

            this.state.valid = this.valid && this.item.type && this.item.type != '';  
            return res
        }
 
    },

    watch: { 
  
        item: function(newVal) { // watch it
            console.log('Prop changed: item', newVal, this.item)
            if(this.isEdit)
                this.$refs.form.validate()
        },

        datePickerValue: function(newVal) { // watch it
            console.log('Prop changed: datePickerValue', newVal, this.item)
            this.item.date_of_cultivation = this.datePickerValue;
            this.state.valid = this.valid && this.item.type && this.item.type != ''; 
        },

        valid: function(newVal) { // watch it
            console.log('Prop changed: valid', newVal, this.item)
            this.state.valid = this.valid && this.item.type && this.item.type != ''; 
        }, 

        isEdit: function(newVal) { // watch it
            console.log('Prop changed: isEdit', newVal, this.item)
            if(!newVal)
                this.$refs.form.reset() 
        },    
        
        isVisible: function(newVal) { // watch it
            console.log('Prop changed: isVisible', newVal, this.item)
            if(newVal)
                this.selectedType.type = this.item.type
            if(!this.isEdit)
                this.$refs.form.reset() 
        },          
    },

    mounted() {
        console.debug('mounted', this.item);
        this.selectedType.type = this.item.type
        if(this.isEdit)
                this.$refs.form.validate()
    }
}
</script>
